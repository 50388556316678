<template>
  <div id="Login" class="login fullheight" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer fluid class="fullheight"> 
      <CRow class="align-start align-md-center fullheight">
        <CCol cols="12" md="12" :style="!is_finished ? 'margin-top: 30px !important' : 'margin-top: 100px !important'">
          <div class="login__container" style="max-width: 600px !important; margin: auto; padding: 30px 20px;">
            <div class="login__inner" style="max-width: 500px !important;" v-show="is_finished">
              <div class="login__image">
                <img src="/main_logo.jpg" style="width: 300px; margin: 0 105px;" alt="Logo Oscuro"> <br><br> La cuenta ha sido creada exitosamente.<br><br>
                Hemos enviado un correo de confirmación a tu cuenta de correo electrónico, en el cual podrás confirmar tu cuenta, activarla y definir tu contraseña de acceso.
              </div>
              <div class="login__form">
                <CRow>
                  <CCol sm="12">
                    <br><CButton type="button" color="primary" style="width: 100%" @click="back">VOLVER</CButton>
                  </CCol>
                </CRow>
              </div>
            </div>
            <div class="login__inner" style="max-width: 500px !important" v-show="!is_finished">
              <div class="login__image">
                <img src="/main_logo.jpg" style="width: 300px; margin: 0 105px;" alt="Logo Oscuro"> <br><br> Ingresa las características del espacio con el que podemos ayudarte.<br><br>
              </div>
              <div class="login__form">
                <CRow>
                  <CCol sm="4">
                    <CSelect
                        label="Pisos: "
                        :isValid="checkIfValid('levels')"
                        :value.sync="$v.form.levels.$model"
                        :options="[{value: 1, label: '1'}, {value: 2, label: '2'}, {value: 3, label: '3+'}]"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                  <CCol sm="4">
                    <CSelect
                        label="Recamaras: "
                        :isValid="checkIfValid('bedrooms')"
                        :value.sync="$v.form.bedrooms.$model"
                        :options="[{value: 1, label: '1'}, {value: 2, label: '2'}, {value: 3, label: '3'}, {value: 4, label: '4'}, {value: 5, label: '5'}, {value: 6, label: '6+'}]"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                  <CCol sm="4">
                    <CSelect
                        label="Baños: "
                        :isValid="checkIfValid('bathrooms')"
                        :value.sync="$v.form.bathrooms.$model"
                        :options="[{value: 1, label: '1'}, {value: 2, label: '2'}, {value: 3, label: '3'}, {value: 4, label: '4'}, {value: 5, label: '5+'}]"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="4">
                    <CSelect
                        label="Estufa: "
                        :isValid="checkIfValid('stove')"
                        :value.sync="$v.form.stove.$model"
                        :options="[{value: false, label: 'No'}, {value: true, label: 'Sí'}]"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                  <CCol sm="4">
                    <CSelect
                        label="Refrigerador: "
                        :isValid="checkIfValid('fridge')"
                        :value.sync="$v.form.fridge.$model"
                        :options="[{value: false, label: 'No'}, {value: true, label: 'Sí'}]"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                  <CCol sm="4">
                    <CSelect
                        label="Sanitización: "
                        :isValid="checkIfValid('sanitization')"
                        :value.sync="$v.form.sanitization.$model"
                        :options="[{value: false, label: 'No'}, {value: true, label: 'Sí'}]"
                        placeholder=""
                        invalidFeedback="Este campo es requerido."
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12" class="text-center">
                    <br />
                    <CButton type="button" color="primary" style="width: 180px; float: right;" @click="singup">COTIZAR SERVICIO</CButton>
                    <CButton type="button" color="dark" style="width: 120px; float: left;" @click="back">CANCELAR</CButton>
                  </CCol>
                </CRow>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>

import router from '../../../router/index'
import login from '../../../services/login';
import general_ws from '../../../services/general';
import store from '../../../store'

import axios from 'axios'

import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"

export default {
    name: 'NewAccountForm',
    components: {

    },
    data () {
        return {
            is_finished: false,
            form: {
                levels: 1,
                bedrooms: 2,
                bathrooms: 1,
                stove: false,
                fridge: false,
                sanitization: false,
                regimen: '626',
                commercial_name: '',
                phone: '',
                email: '',
                password: '',
                state: 'JAL',
                company_type: 'services',
                company_size: 'small',
                admin_tool: 'none',
                huso_horario: 'CENTRO',
                system: '',

            },
            rfc_error: 0,
            email_error: 0,
            show_password: false,
            submitted: false,
            states: [],
            statesOptions: [],
            regimens: [],
            regimensOptions: [],
        }
    },
    mounted: async function() {
      let response = await general_ws.getRegimens(); 

      if(response.type == "success"){
        this.regimens = response.data;
      }

      let response2 = await general_ws.getStates(); 

      if(response2.type == "success"){
        this.states = response2.data;
      }

      this.regimensOptions = await this.$parseSelectOptionsOrdered(this.regimens, "code", "code:name");
      this.statesOptions = await this.$parseSelectOptionsOrdered(this.states, "code", "name");
    },
    methods: {
        async singup () {
            this.$v.$touch();

            if(!this.validatePassword(this.form.password)){
              return;
            }
            let resRFC = await this.validateRFC(this.form.rfc);
            if(!resRFC){
              return false;
            }
            let resEmail = await this.validateEmail(this.form.email);
            if(!resEmail){
              return false;
            }

            if (this.isValid) {
                this.submitted = true;
                
                let response = await login.storeAccount(this.form);
                
                if(response.type == "success"){
                  //SI SE DEFINIÓ LA CONTRASEÑA SE HACE EL PRIMER LOGIN
                  if(this.form.password.length >= 8){
                    let params = {
                      email: this.form.email,
                      password: this.form.password,
                      device_name: navigator.userAgent
                    };

                    let response = await login.login(params);

                    if(response.type == "success"){
                      let data = response.data;

                      localStorage.setItem("token", data.api_token);

                      axios.defaults.headers.common['api-token'] = data.api_token;

                      router.push("home");
                    }
                  }
                  else{
                    //SI NO SE DEFINIÓ LA CONTRASEÑA - SE ENVIA UN CORREO DE ACTIVACIÓN
                  }
                  
                  this.is_finished = true;
                }
                else{
                  alert(response.message);
                }
            }
            
        },
        back(){
          router.push("login");
        },
        async validateRFC (rfc) {
            let response = await login.validateRFC(rfc);
            
            if(response.type == "success" && response.data == 1){
              alert(response.message);
              this.form.rfc = "";

              return false;
            }

            return true;
        },
        async validateEmail (email) {
            let response = await login.validateEmail(email);

            if(response.type == "success" && response.data == 1){              
              alert(response.message);
              this.form.email = "";

              return false;
            }

            return true;
        },
        validatePassword (password) {
            if(password.length > 0 && password.length < 8){
              alert("El campo contraseña debe tener al menos 8 caracteres");
              return false;
            }

            return true;
        },
        checkIfValid (fieldName) {
            const field = this.$v.form[fieldName]
            if (!field.$dirty) {
                return null
            }

            if(fieldName == "email"){
              if(field.$model == "raalveco@gmail.com"){
                this.show_password = true;
              }
            }
            
            return !(field.$invalid)
        },
        showToast(type, message){
            store.commit('toast', {color: type, message: message});
        },
        loading(){
            store.commit('loading');
        },
        loaded(){
            store.commit('loaded');
        }
    },
    computed: {
        formString () { return JSON.stringify(this.form, null, 4) },
        isValid () { return !this.$v.form.$invalid },
        isDirty () { return this.$v.form.$anyDirty },
    },
    mixins: [validationMixin],
    validations: {
        form: {
            levels: {
                required
            },
            bedrooms: {
                required
            },
            bathrooms: {
                required
            },
            stove: {
                required
            },
            fridge: {
                required
            },
            sanitization: {
                required
            },
            rfc: {
                required
            },
            name: {
                required
            },
            regimen: {
                required
            },
            commercial_name: {
                required
            },
            phone: {
                required
            },
            email: {
                required,
                email
            },
            password: {},
            state: {
                required
            },
            huso_horario: {},
            company_type: {
                required
            },
            company_size: {
                required
            },
            admin_tool: {
                required
            },
            system: {}
        }
    },
}
</script>